import { Component, OnInit } from '@angular/core';
import { Flashcard,FlashcardItemId } from '../types/index';
import { FlashcardGatewayService } from '../services/flashcard-gateway.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  model = new Flashcard();
  modelid = new FlashcardItemId();

  constructor(private flashcardGatewayService: FlashcardGatewayService) { }

  ngOnInit(): void {
  }

  submitForm(){
    this.flashcardGatewayService.addFlashCard(this.model);
  }

  publishItem(){
    this.flashcardGatewayService.publishItem(this.modelid);
  }

  unpublishItem(){
    this.flashcardGatewayService.unpublishItem(this.modelid);
  }

  getItem(){
    this.flashcardGatewayService.getItem(this.modelid).then(response => {
      this.model.itemid = response[1].itemid;
      this.model.setid = response[1].setid;
      this.model.setname = response[0].setname;
      this.model.front = response[1].front;
      this.model.back = response[1].back;
    });
  }

  removeItem(){
    this.flashcardGatewayService.removeItem(this.modelid);
  }
  get diagnostic() { return JSON.stringify(this.model); }
}
