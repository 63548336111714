<div class="container">
    <h1>Flashcard Form</h1>
    <form (ngSubmit)="getItem()" #flashcardFrom="ngForm">
      <div class="columns">
        <div class class="column is-2">
          <div class="form-group">
            <label class="label labelstyle">Item Id</label>
            <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
          </div>
        </div>
      </div>
      <button type="submit" class="btn btn-success">Get Item</button>
    </form>
  <br/>
  <br/>
  <br/>
    <form (ngSubmit)="submitForm()" #flashcardFrom="ngForm">
      <div class="columns">
        <div class class="column is-2">
          <div class="form-group">
            <label class="label labelstyle">Item Id</label>
            <input type="text" class="form-control" id="itemid" required [(ngModel)]="model.itemid" name="itemid">
          </div>
        </div>
        <div class class="column is-2">
          <div class="form-group">
            <label class="label labelstyle">Set Id</label>
            <input type="text" class="form-control" id="setid" required [(ngModel)]="model.setid" name="setid">
          </div>
        </div>
        <div class class="column is-3">
          <div class="form-group">
            <label class="label labelstyle">Set Name</label>
            <input type="text" class="form-control" id="setname" required [(ngModel)]="model.setname" name="setname">
          </div>
      </div>
      </div>
      <div class="form-group">
        <label class="label labelstyle">Front</label>
        <textarea type="text" class="form-control cardbox" id="front" required [(ngModel)]="model.front" name="front"></textarea>
      </div>
      <div class="form-group">
        <label class="label labelstyle">Back</label>
        <textarea type="text" class="form-control cardbox" id="back" required [(ngModel)]="model.back" name="back"></textarea>
      </div>
  
      <button type="submit" class="btn btn-success">Submit</button>
    </form>
    <br/>
    <br/>
    <br/>
    <br/>
  <br/>
  <br/>
  <form (ngSubmit)="publishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Publish Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="unpublishItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Unpublish Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  <form (ngSubmit)="removeItem()" #quizFrom="ngForm">
    <div class="columns">
      <div class class="column is-2">
        <div class="form-group">
          <label class="label labelstyle">Item Id</label>
          <input type="text" class="form-control" id="itemid" required [(ngModel)]="modelid.itemid" name="itemid">
        </div>
      </div>
    </div>
    <button type="submit" class="btn btn-success">Remove Item</button>
  </form>
  <br/>
  <br/>
  <br/>
  </div>
  