import { Injectable } from '@angular/core';
import { API, Auth } from 'aws-amplify';
import { Flashcard } from '../types/index';

@Injectable({
  providedIn: 'root'
})
export class FlashcardGatewayService {
  constructor(){}

  async addFlashCard(flashcard: Flashcard){
    const apiName = 'er4FlashcardAdm';
    const path = '/additem';

    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: flashcard
    };

    API.post(apiName, path, myInit);
  }

  async getItem(itemid): Promise<any>{
    const apiName = 'er4FlashcardAdm';
    const path = '/getitem';
    let returnvalue: Flashcard;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async publishItem(itemid): Promise<any>{
    const apiName = 'er4FlashcardAdm';
    const path = '/publishitem';
    let returnvalue: Flashcard;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async unpublishItem(itemid): Promise<any>{
    const apiName = 'er4FlashcardAdm';
    const path = '/unpublishitem';
    let returnvalue: Flashcard;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

  async removeItem(itemid): Promise<any>{
    const apiName = 'er4FlashcardAdm';
    const path = '/removeitem';
    let returnvalue: Flashcard;
    const myInit = {
      headers: {
        Authorization: `Bearer ${(await Auth.currentSession()).getIdToken().getJwtToken()}`,
      },
      body: itemid
    };

    await API.post(apiName, path, myInit)
      .then(response => {
        returnvalue = response;
      })
      .catch(error => {
        return error;
      });
    return returnvalue;
  }

}
